<template>
    <div class="summary-card pt-5 pt-lg-0">
        <!-- <div class="alignment"> -->
        <b-row v-if="isDistributorSurgeonMode" class="p-4">
            <b-col>
                <b-button
                    @click="showSubmitPreOrderPrompt = true"
                    v-if="$route.name === 'ShoppingCart'"
                    :disabled="!summary.itemsCount"
                    variant="primary"
                    class="btn-block-xs-only mt-2 mr-0 mr-sm-2"
                >
                    {{ t('preorder') }}
                </b-button>
                <b-button
                    v-if="summary.isCartSummary"
                    to="/patients"
                    variant="secondary"
                    class="btn-block-xs-only mt-2"
                >
                    {{ t('checkout_ContinueShopping') }}
                </b-button>
            </b-col>
        </b-row>
        <div
            v-else
            :class="{'border border-dark rounded p-4': isShoppingCartRoute || hasPricingPermission}"
        >
            <b-form-row v-if="summary.isCartSummary">
                <b-col>
                    <h5 class="font-weight-bold">{{ t('orderType') }}</h5>
                </b-col>
                <b-col class="text-right">
                    <b-form-group v-if="isShoppingCartRoute">
                        <b-form-select
                            id="select-order-type"
                            :options="orderTypes"
                            :value="shoppingCart.orderTypeId"
                            @change="updateOrderType"
                            text-field="orderTypeCode"
                            value-field="orderTypeId"
                            required
                            plain
                        ></b-form-select>
                    </b-form-group>
                    <span v-else>
                        {{ orderTypeSelected }}
                    </span>
                </b-col>
            </b-form-row>
            <template v-if="hasPricingPermission">
                <b-row>
                    <b-col>
                        <h5 class="font-weight-bold">
                            {{ t('checkout_OrderSummary') }}
                        </h5>
                    </b-col>
                </b-row>

                <b-row v-if="!hasAdditionalLenses" class="py-3 mx-0 border-bottom">
                    <b-col xl="6" class="px-0">
                        {{ t('checkout_Price') }} ({{ itemsCountDisplay }} items)
                    </b-col>
                    <b-col xl="6" class="px-0 text-right">{{
                        summary.orderTotal | toCurrency
                    }}</b-col>
                </b-row>

                <template v-else>
                    <b-row class="pb-1 pt-3">
                        <b-col xl="6">{{ t('primaryLenses') }}</b-col>
                        <b-col xl="6" class="text-right">
                            {{ totalPrimary | toCurrency }}
                        </b-col>
                    </b-row>

                    <b-row class="py-1">
                        <b-col xl="6">{{ t('additionalLenses') }}*</b-col>
                        <b-col xl="6" class="text-right">
                            {{ totalAdditional | toCurrency }}
                        </b-col>
                    </b-row>

                    <b-row class="border-bottom pb-3 pt-1 mx-0">
                        <b-col xl="6" class="px-0">{{ t('includedAccessories') }}</b-col>
                        <b-col xl="6" class="px-0 text-right">
                            {{ totalAccessories | toCurrency }}
                        </b-col>
                    </b-row>
                </template>

                <b-row v-if="!isBulkOrder" class="py-3 mx-0 border-bottom">
                    <b-col xl="6" class="px-0">{{ t('checkout_DeliveryChange') }}</b-col>
                    <b-col xl="6" class="px-0 text-right">{{
                        summary.fixedFreightCharge | toCurrency
                    }}</b-col>
                </b-row>
                <b-row
                    :class="{
                        'py-3': true,
                        'mx-0': summary.ordersCount > 1,
                        'border-bottom': summary.ordersCount > 1,
                    }"
                >
                    <b-col xl="6">{{ t('checkout_TotalPrice') }}</b-col>
                    <b-col xl="6" class="font-weight-bold text-right">
                        {{ (summary.orderTotal + summary.fixedFreightCharge) | toCurrency }}
                    </b-col>
                </b-row>
                <b-row
                    class="py-3 mx-0 text-justify text-caption"
                    v-if="!activeCustomerBusinessUnit || activeCustomerBusinessUnit === BUCodes.CH"
                >
                    {{ $t('notVatIncluded') }}
                </b-row>

                <b-row
                    v-if="hasAdditionalLenses"
                    class="pb-3 mx-0 border-bottom text-caption font-italic"
                >
                    *
                    <template
                        v-if="
                            activeCustomerBusinessUnit === BUCodes.CH ||
                            activeCustomerBusinessUnit === BUCodes.JP
                        "
                        >{{ t('additionalLensesOUS', {addDays}) }}</template
                    >
                    <template v-if="activeCustomerBusinessUnit === BUCodes.US">{{
                        t('additionalLensesUS', {addDays})
                    }}</template>
                </b-row>

                <b-row v-if="summary.ordersCount > 1" class="py-3">
                    <b-col xl="6">{{ t('checkout_TotalOrdersCreated') }}</b-col>
                    <b-col xl="6" class="font-weight-bold text-right">{{
                        summary.ordersCount
                    }}</b-col>
                </b-row>
            </template>
            <b-row class="mt-3">
                <b-col>
                    <b-button
                        @click="goTo"
                        v-if="toggleButtonName"
                        :disabled="!summary.itemsCount || (isShoppingCartRoute && isCartLoading)"
                        variant="primary"
                        class="btn-block-xs-only mt-2 mr-0 mr-sm-2"
                    >
                        {{ toggleButtonName }}
                    </b-button>
                    <b-button
                        v-if="summary.isCartSummary"
                        to="/patients"
                        variant="secondary"
                        class="btn-block-xs-only mt-2"
                    >
                        {{ t('checkout_ContinueShopping') }}
                    </b-button>
                </b-col>
            </b-row>
        </div>
        <!-- </div> -->
        <b-overlay :show="showSubmitPreOrderPrompt" no-wrap fixed>
            <template #overlay>
                <div
                    ref="dialog"
                    tabindex="-1"
                    role="dialog"
                    aria-modal="false"
                    aria-labelledby="form-confirm-label"
                    class="text-center p-3"
                >
                    <h3>
                        <strong
                            id="form-confirm-label"
                            v-html="
                                t(hasMtoLenses ? 'submitPreorderWithMto' : 'submitPreorder', {
                                    customerName: activeCustomer.name,
                                })
                            "
                        >
                        </strong>
                    </h3>
                    <div class="d-flex center mt-3">
                        <b-button
                            variant="outline-danger"
                            size="lg"
                            class="mr-3"
                            @click="showSubmitPreOrderPrompt = false"
                        >
                            {{ t('no') }}
                        </b-button>
                        <b-button variant="outline-success" size="lg" @click="placePreOrder">{{
                            t('yes')
                        }}</b-button>
                    </div>
                </div>
            </template>
        </b-overlay>
    </div>
</template>

<script>
import get from 'lodash/get';
import {mapGetters, mapState, mapActions} from 'vuex';
import {ZoneCodes} from '@/constants/zone';
import {getCheckoutRouteNames} from '@/utilities/router';
import {BUCodes} from '@/constants/user.js';

export default {
    name: 'SummaryCard',
    data() {
        return {
            ZoneCodes,
            showSubmitPreOrderPrompt: false,
        };
    },
    computed: {
        ...mapState({
            shoppingCart: (state) => state.cart.shoppingCart,
            isCartLoading: (state) => state.cart.loading,
        }),
        ...mapGetters('user', ['activeCustomer', 'activeCustomerBusinessUnit']),
        ...mapGetters('cart', ['summary', 'hasMtoLenses', 'orderTypes', 'getSummaryValues']),
        ...mapGetters('zone', ['currentZone']),
        ...mapGetters('orders', ['getOrderConfirmations', 'getBulkItemsNumber']),

        BUCodes() {
            return BUCodes;
        },
        isDistributorSurgeonMode() {
            return this.checkPermissions({
                [this.PERMISSIONS.DISTRIBUTOR_MODE]: [this.PERMISSIONS_VALUES.SURGEON],
            });
        },
        hasPricingPermission() {
            return this.checkPermissions({
                [this.PERMISSIONS.PRICING]: [this.PERMISSIONS_VALUES.ENABLED],
            });
        },
        isShoppingCartRoute() {
            return this.$route.name === 'ShoppingCart';
        },
        toggleButtonName() {
            let buttonName = '';
            let checkoutRouteNames = getCheckoutRouteNames();
            if (checkoutRouteNames.includes(this.$route.name)) {
                buttonName = this.t('checkout_EditCart');
            } else if (this.isShoppingCartRoute) {
                buttonName = this.t('checkout');
            }
            return buttonName;
        },
        orderTypeSelected() {
            if (this.$route.name === 'OrderDetails') {
                const orderTypeSelected = this.getOrderConfirmations[0]?.orderTypeName || '';
                return orderTypeSelected;
            }
            const orderTypeSelected = (this.orderTypes || []).find(
                (ot) => ot.orderTypeId == this.shoppingCart.orderTypeId
            );
            return get(orderTypeSelected, 'orderTypeCode', '');
        },
        addDays() {
            return this.shoppingCart.additionalLensesReturnDays;
        },

        hasAdditionalLenses() {
            if (this.$route.name === 'OrderConfirmation') {
                return this.summaryValues[0];
            }

            const items =
                this.$route.name === 'OrderDetails'
                    ? this.getOrderConfirmations.flatMap((order) => order.items)
                    : this.shoppingCart.items;

            return items.some((item) => item.itemType === 'LENS' && !item.isPrimaryLens);
        },

        totalPrimary() {
            if (this.$route.name === 'OrderConfirmation') {
                return this.summaryValues[1];
            }

            const items =
                this.$route.name === 'OrderDetails'
                    ? this.getOrderConfirmations.flatMap((order) => order.items)
                    : this.shoppingCart.items;

            return items
                .filter((item) => item.itemType === 'LENS' && item.isPrimaryLens)
                .reduce((sum, item) => sum + item.price, 0);
        },

        totalAdditional() {
            if (this.$route.name === 'OrderConfirmation') {
                return this.summaryValues[2];
            }

            const items =
                this.$route.name === 'OrderDetails'
                    ? this.getOrderConfirmations.flatMap((order) => order.items)
                    : this.shoppingCart.items;

            return items
                .filter((item) => item.itemType === 'LENS' && !item.isPrimaryLens)
                .reduce((sum, item) => sum + item.price, 0);
        },

        totalAccessories() {
            if (this.$route.name === 'OrderConfirmation') {
                return this.summaryValues[3];
            }

            const items =
                this.$route.name === 'OrderDetails'
                    ? this.getOrderConfirmations.flatMap((order) => order.items)
                    : this.shoppingCart.items;

            return items
                .filter((item) => item.itemType === 'ACCESSORY' && item.quantity > 0)
                .reduce((sum, item) => sum + item.quantity * item.price, 0);
        },

        isBulkOrder() {
            return window.location.href.includes('/bulkorder/');
        },
        summaryValues() {
            return this.getSummaryValues;
        },
        itemsCountDisplay() {
            return this.$route.name === 'BulkOrderDetails'
                ? this.getBulkItemsNumber
                : this.summary.itemsCount;
        },
    },
    methods: {
        ...mapActions('cart', ['fetchItemsCount', 'fetchItems', 'updateCheckout']),
        goTo() {
            let routeName = '';
            let checkoutRouteNames = getCheckoutRouteNames();
            if (checkoutRouteNames.includes(this.$route.name)) {
                routeName = 'ShoppingCart';
            } else if (this.$route.name === 'ShoppingCart') {
                routeName = 'ShippingDetails';
            }
            this.$router.push({
                name: routeName,
            });
        },
        /**
         * Places the pre order
         *
         * After the order has been placed, the user is routed to the order
         * confirmation page and the item data is updated.
         */
        async placePreOrder() {
            const {success, results} = await this.blockingRequest(
                'orders/submit',
                this.shoppingCart
            );
            if (success) {
                this.showSubmitPreOrderPrompt = false;
                this.$router.push({
                    name: 'PreOrderConfirmation',
                    params: {
                        defaultOrder: results,
                        isConfirmingOrder: true,
                    },
                });
                await this.fetchItemsCount();
                await this.fetchItems();
            }
        },
        async updateOrderType(orderTypeId) {
            try {
                const payload = {...this.shoppingCart, orderTypeId};
                const {success, error} = await this.updateCheckout(payload);
                if (!success) throw error;
            } catch (error) {
                this.$bvToast.toast(error?.errors?.join(' ') || this.t('checkout_Error'), {
                    title: error?.errorCode || this.t('warning'),
                    variant: 'warning',
                    solid: true,
                });
            }
        },
    },
};
</script>
<style lang="scss" scoped></style>
